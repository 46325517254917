.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 1s ease-in-out alternate;
  }
}
body{

  background-color: #0c192c; 
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  0%{
    transform: translateY(0);
  }
  100% {
    transform: translateY(-13px);
  }
}
.bubbles{
  position: fixed;
  display: flex;
}
.bubbles span{
  position: relative;
  width:30px;
  height: 30px;
  background: rgb(255, 123, 0);
  margin: 0 4px;
  border-radius:50%;
  box-shadow: 0 0 0 10px rgb(192, 96, 7), 0 0 50px rgb(255, 123, 0), 0 0 100px rgb(255, 123, 0);
  animation: animate 15s linear infinite;
  animation-duration: calc(130s /var(--i));
}

@keyframes animate{
  0%{
    transform: translateY(100vh) scale(0);
  }
  100%{
    transform: translateY(-10vh) scale(1);
    opacity: 0;
  }
}